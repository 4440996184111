@import "react-toastify/dist/ReactToastify.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  overflow-x: hidden;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.chakra-progress svg {
  height: 30px;
  width: 30px;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}


.roi-selection-dropdown svg {
  width: 20px;
  height: 20px;
}

.time-selection-menulist {
  position: absolute;
  top: -22px;
  left: -8px;
}

.leaderboard-table-header .menulist-wrapper {
  min-width: 150px;
}

.react-datepicker__current-month {
  color: white !important;
}

.calendar-modal-content .react-datepicker__header {
  padding-top: 0;
  border: transparent !important;
  background-color: transparent !important;
}

.calendar-modal-content .react-datepicker {
  border: none;
}

.calendar-modal-content .react-datepicker__day-name,
.react-datepicker__time-name {
  color: #FF9100 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  font-family: 'Inter';
  z-index: 1;
}

.react-datepicker__day {
  color: white !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  font-family: 'Inter';
  z-index: 1;
}

.calendar-modal-content .react-datepicker__day-name {
  font-family: 'Poppins';
}

.calendar-modal-content .react-datepicker__month {
  margin: 0 !important;
  width: 250px !important;
}

.calendar-modal-content .react-datepicker__day-names {
  padding-top: 3px;
  padding-bottom: 12px;
}

.calendar-modal-content .react-datepicker__day--today {
  border-radius: 9px !important;
  color: white !important;
  border: none !important;
}

.calendar-modal-content .react-datepicker__day--outside-month {
  color: #B7B7B7 !important;
}

.calendar-modal-body .react-datepicker__month-container {
  background: #050023 !important;
}

.calendar-modal-body .react-datepicker__week {
  margin-bottom: 10px;
  border-radius: 9px;
  width: 250px;
}

.calendar-modal-body .react-datepicker__week:last-child {
  margin-bottom: 0px !important;
}

.react-datepicker__day {
  margin: 0 !important;
  /* width: 24px !important;
    height: 24px !important; */
}

.react-datepicker__day-names {
  column-gap: 4px;
  display: flex;
}

.react-datepicker__week {
  display: flex;
  column-gap: 10px;
}

.react-datepicker__day--selected {
  border-radius: 9px !important;
  background: #FF9100 !important;
}

.react-datepicker__day--selected:focus-visible {
  outline: none;
}

.react-datepicker__navigation:focus-visible {
  outline: none;
}

.countdown-in-detail-page div {
  color: white;
}

.sort-by-dropdown-wrapper>div {
  z-index: 2;
}

.loading-container {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .loading-container section {
    min-width: 350px;
  }
}

.loader {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}

.loader-1 .loader-outter {
  position: absolute;
  border: 2px solid #739AF0;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-outter 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loader-1 .loader-inner {
  position: absolute;
  border: 2px solid #739AF0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
  animation: loader-1-inner 1s cubic-bezier(.42, .61, .58, .41) infinite;
}

.loading-container section {
  display: flex;
  justify-content: center;
}

.loading-container,
.loading-container::before,
.loading-container::after,
.loading-container section,
.loading-container section::after,
.loading-container::after,
.loading-container .loader-1,
.loading-container .loader-1::before,
.loading-container .loader-1::after,
.loader-outter,
.loader-outter::before,
.loader-outter::after,
.loader-inner,
.loader-inner::before,
.loader-inner::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ----------------     KEYFRAMES    ----------------- */

@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.nft-category select option,
.sell-duration-select-wrapper select option {
  /* background-color: #050023; */
  color: white;
  background: #050023;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker-popper {
  border: 1px solid white;
  margin-top: 10px;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker {
  border: none;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker__day-names .react-datepicker__day-name {
  color: #FF9100;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker__input-time-container {
  margin: 0 !important;
  background: #050023 !important;
  padding: 10px 10px;
  justify-self: center;
  text-align: center;
  border: none;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker-time__input {
  margin: 0 !important;
  background: #050023 !important;
  color: white;
  margin-left: 10px !important;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker-time__caption {
  color: white;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker__input-container input {
  background: #050023;
  border-bottom: 2px solid #739AF0;
  width: 300px;
  font-family: 'Poppins';
  font-size: 18px;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker__header {
  background: #050023;
  border: none;
}

.event-start-time-picker-wrapper-in-admin-page .react-datepicker__month-container {
  background: #050023;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(100%) sepia(2%) saturate(1398%) hue-rotate(231deg) brightness(125%) contrast(100%);
}

.my-bid-table tr th {
  text-align: center;
  color: white;
  font-family: 'Poppins';
}

.my-bid-table tr td {
  text-align: center;
}

.my-bid-table .control-button svg {
  width: 24px;
  color: #739AF0;
}

.chain-logo-menu-wrapper>div>div,
.sort-by-component>div>div>div,
.find-by-component>div:nth-child(3)>div>div,
.group-select-dropdown-wrapper>div>div {
  border: 1px solid #99b7ff;
}

.category-select {
  option {
    background: #050023 !important;
  }
}

.Toastify__toast-icon {
  width: 25px !important;
}